<template>
  <div class="list">
    <div class="header">
      <h3 class="sub-title">
        {{title}}
      </h3>
      <div class="button-box">
        <!-- <v-btn
          color="white"
          class="red--text"
        >
        <v-icon
          left
          dark
        >
          mdi-microsoft-excel
        </v-icon>
          Сформировать отчет
        </v-btn> -->
      </div>
    </div>
    <div class="responsive">
      <v-skeleton-loader
       v-if="!load"
        class="mx-auto"
        type="table-tbody"
      ></v-skeleton-loader>
      <table class="table table-search table-no-link" v-else>
        <thead>
          <tr>
            <th
              v-for="header in headers"
              :key="header.text"
              class="text-center">
              {{header.text}}
              <div class="search">
                <input type="text">
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(table, index) in getReports"
            :key="index"
          >
            <td class="text-center">{{table.code}}</td>
            <td class="text-center name">{{table.standartName}}</td>
            <td class="text-center">{{table.clinrec}}</td>
            <td class="text-center">{{table.fullName}}</td>
            <td class="text-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                  v-bind="attrs"
                  v-on="on">{{table.depNumber}}</span>
                </template>
                <span>{{table.department}}</span>
              </v-tooltip>
            </td>
            <td class="text-center">{{table.drugName}}</td>
            <td class="text-center">{{table.drugSuggestedValueName}}</td>
              <td class="text-center">{{table.avgProvisionFrequency}}</td>
              <td class="text-center">{{table.ssd}}</td>
            <td class="text-center">{{table.skd}}</td>
            <td class="text-center">{{table.changeJustification}}</td>
            <td class="text-center">{{table.createDate | moment("DD-MM-YYYY")}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions, mapMutations} from 'vuex'
  export default {
    name: 'ListMedicines',
    data () {
      return {
        title: 'Список замечаний (Лекарства)',
        load: false,
        headers: [
          {text: 'Код СМП'},
          {text: 'Наименование стандарта'},
          {text: 'Клиническая рекомендация'},
          {text: 'ФИО согласующего'},
          {text: 'Департамент'},
          {text: 'Наименование лекарства'},
          {text: 'Предлагаемые значения'},
          {text: 'Усредненная частота предоставления'},
          {text: 'ССД'},
          {text: 'СКД'},
          {text: 'Обоснование изменения'},
          {text: 'Дата добавления замечания'}
        ],
      }
    },
    computed : {
      ...mapGetters({
          getReports: 'reports/getReports',
      }),
    },
    methods: {
      ...mapActions({
          reports: 'reports/reports',
      }),
      ...mapMutations({
          setSearch: 'reports/setSearch',
      }),
    },
    mounted() {
      this.reports('drug').then(() => this.load = true)
    }
  }
</script>
